body {

	font-weight: 300;
	line-height: 1.42em;
	color:#000000;
	background-color:#ffffff;
	
  }
  
  h1 {
	font-size:3em; 
	font-weight: 300;
	line-height:1em;
	text-align: center;
	color: #000000;
  }
  
  h2 {
	font-size:1em; 
	font-weight: 300;
	text-align: center;
	display: block;
	line-height:1em;
	padding-bottom: 2em;
	color: #000000;
  }
  
  h2 a {
	font-weight: 700;
	text-transform: uppercase;
	color: #000000;
	text-decoration: none;
  }
  
.content{
    padding-left: 15%;
}

*{
	box-sizing: border-box;
}

.table{
	width: 100%;
	border-collapse: collapse;

}

.table td,.table th{
  padding:12px 15px;
  text-align: left;
  font-size:16px;

}

.table th{
	
	color:#000000;
}
.tabMainContainer{
    padding-left: 20%;
}

.table tr:hover {
    background-color: #ffffff;
 -webkit-box-shadow: 0 6px 6px -6px #000000;
        -moz-box-shadow: 0 6px 6px -6px #000000;
             box-shadow: 0 6px 6px -6px #000000;
 }
/*responsive*/
@media screen and (max-width: 1000px){
    .tabMainContainer{
        padding-left: 10%;
    }
}
@media screen and (max-width: 900px){
    .tabMainContainer{
        padding-left: 0%;
    }
}
@media screen and (max-width: 500px){
    .tabs{
        padding-left: 0%;
        font-size: 18px;
    }
	.table thead{
		display: none;
	}
    .tabMainContainer{
        padding-left: 0%;
		padding-bottom: 40%;
		margin-bottom: 800px;
		
    }
	.table, .table tbody, .table tr, .table td{
		display: block;
		width: 100%;
		
	}
	.table tr{
		margin-bottom:15px;
	}
	.table td{
		text-align: left;
		padding-left: 60%;
		text-align: left;
		position: relative;
		
	}
    .table tr {
        background-color: #ffffff;
     -webkit-box-shadow: 0 6px 6px -6px #000000;
            -moz-box-shadow: 0 6px 6px -6px #000000;
                 box-shadow: 0 6px 6px -6px #000000;
     }
     .table td:first-child { 
        padding-left: 0%;
        text-align:left;
        color: #000000; 
        font-weight: bold;
        }
       
	.table th:first-child{
        display:none;
    }
    .table td::before{
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 50%;
		padding-left:15px;
		font-size:15px;
		font-weight: bold;
		text-align: left;
	}
}
