nav {
    width: 100%;
    background: #1E2022;
    color: #C8B170;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0.5rem; */
    height: 45px; 
    width: 100%; 
}

.phone {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: "Helvetica Neue", "Arial", sans-serif;
    align-items: flex-start;
    flex-flow: column-reverse;
    justify-content: flex-end;
    flex: 1;
    padding-left: 1rem;
}

.topBarItem{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
    flex-flow: column-reverse;
    font-family: "Helvetica Neue", "Arial", sans-serif;
    justify-content: flex-start;
    flex: 1;
    padding-right: 2rem;
  }
.fbImg {
    height: 45px; 
}

@media screen and (max-width: 500px){
    .phone {
        font-size: 12px;
    }
}