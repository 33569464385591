
.Root {
    margin-top:20px;
  }
  
  .Tabs {
    height: 100%;
    display: flex;
  }
  
  .TabList {
    width: 7rem;
    background: #000000;
  }
  
  .TabPanel {
    flex: 1;
    display: none;
    padding: 1rem 2rem;
  }
  .TabPanel.is-active {
    display: block;
  }
  
  .Button {
    display: inline-block;
    width: 100%;
    padding: 1.2rem 0.6rem;
    background: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
  }
  .Button:focus,
  .Button:hover {
    outline: 0;
    background: #37474F;
  }
  .Tab.is-active .Button {
    background: #C8B16F;
  }
  .TabList{
    list-style-type: none;
}


@media screen and (min-width: 500px) {
    .Root{
        padding: 30px 250px;
    }
    .TabList{
        list-style-type: none;
    }
}